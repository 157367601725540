import React from "react";
import { Provider } from "react-alert";
import Template from "./Template";


const options = {
  position: 'top center',
  timeout: 3000,
  offset: '30px',
  transition: 'scale'
};

const AlertProvider = props => <Provider template={Template} {...options} {...props} />;

export default AlertProvider;
