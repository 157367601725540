import React from "react";


const BaseIcon = ({ color, pushRight, ...props }) => {
  const shouldPush = pushRight || pushRight === undefined;
  const svgStyle = { minWidth: 24, marginRight: shouldPush ? "20px" : "0" };
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="24" height="24" viewBox="0 0 24 24"
      fill="none" stroke={color} strokeWidth="2"
      strokeLinecap="round" strokeLinejoin="round"
      style={svgStyle} {...props} />
  );
};


const circleProps = { cx: "12", cy: "12", r: "10" };


export const InfoIcon = props => {
  const dotProps = { x1: "12", y1: "8", x2: "12", y2: "8" },
        lineProps = { x1: "12", y1: "16", x2: "12", y2: "12" };

  return (
    <BaseIcon {...props}>
      <circle {...circleProps} />
      <line {...dotProps} />
      <line {...lineProps} />
    </BaseIcon>
  );
};


export const SuccessIcon = props => (
  <BaseIcon {...props}>
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
    <polyline points="22 4 12 14.01 9 11.01" />
  </BaseIcon>
);


export const ErrorIcon = props => {
  const lineProps = { x1: "12", y1: "8", x2: "12", y2: "12" },
        dotProps = { x1: "12", y1: "16", x2: "12", y2: "16" };

  return (
    <BaseIcon {...props}>
      <circle {...circleProps} />
      <line {...lineProps} />
      <line {...dotProps} />
    </BaseIcon>
  );
};


export const CloseIcon = props => {
  const line1Props = { x1: "18", y1: "6", x2: "6", y2: "18" },
        line2Props = { x1: "6", y1: "6", x2: "18", y2: "18" };

  return (
    <BaseIcon color="#FFFFFF" pushRight={false} {...props}>
      <line {...line1Props} />
      <line {...line2Props} />
    </BaseIcon>
  );
};
