import "@babel/polyfill";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { withAlert } from "react-alert";
import AlertProvider from "Components/Alert";
import { Columns, Column, Section } from "bloomer";
import { Subtitle, Button, Field, Input } from "bloomer";
import styles from "./login.css";


const LoginBox = ({ children }) => (
  <Section className="login-box">
    <Columns isCentered>
      <Column isSize="1/3" hasTextAlign="centered">
        {children}
      </Column>
    </Columns>
  </Section>
);

const LoginText = () => (
  <Subtitle isSize={1} hasTextColor="white">
    Log In
  </Subtitle>
);

const InputField = props => {
  const className = "is-flat is-rounded is-medium";
  return (
    <Field>
      <Input required className={className} {...props} />
    </Field>
  );
};

const SubmitButton = props => {
  const className = "is-rounded is-fullwidth is-medium is-inverted is-outlined is-info";
  return (
    <Field>
      <Button type="submit" className={className}>
        Log In
      </Button>
    </Field>
  );
};


class Login extends Component
{
  state = { email: "", password: "" };

  handleInput = ({ target }) => this.setState({[target.name]: target.value});


  fetchLogin = async () => {
    const body = {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: { "content-type": "application/json" },
      credentials: "include"
    };
    const response = await fetch("/login/init", body);
    return response.json();
  };


  handleError = message => {
    this.setState({ password: "" });
    this.props.alert.error(message, { timeout: 5000 });
  };


  handleLogin = async (event) => {
    event.preventDefault();
    try {
      const { data, status, message } = await this.fetchLogin();
      if (status !== "success") return this.handleError(message);
      window.location.replace(data.returnToPage);
    }
    catch(err) {
      console.warn(err);
      this.handleError("Error - please try again");
    }
  };


  render()
  {
    return (
      <LoginBox>
        <LoginText />
        <form onSubmit={this.handleLogin}>
          <InputField
            type="email"
            name="email"
            placeholder="Email"
            value={this.state.email}
            onChange={this.handleInput} />
          <InputField
            type="password"
            name="password"
            placeholder="Password"
            value={this.state.password}
            onChange={this.handleInput} />
          <SubmitButton />
        </form>
      </LoginBox>
    );
  }
}

const LoginRoot = withAlert(Login);

ReactDOM.render(
  <AlertProvider>
    <LoginRoot />
  </AlertProvider>,
  document.getElementById("root")
);
