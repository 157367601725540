
export function getById(id, arr)
{
  return (Array.isArray(arr) && arr.find(el => el.id == id)) || {};
}


export function bindAll(methods, target)
{
  for (var funcName in methods)
  {
    target[funcName] = methods[funcName].bind(target);
  }
}


export function makeCancelable(promise)
{
  let shouldCancel = false;

  const WrappedPromise = new Promise(async (resolve, reject) => {
    try
    {
      let val = await promise;
      shouldCancel ? reject({ isCanceled: true }) : resolve(val);
    }
    catch(err)
    {
      reject(shouldCancel ? { isCanceled: true } : err);
    }
  });

  return {
    promise: WrappedPromise,
    cancel() { shouldCancel = true; }
  };
}


export function clipText(text = "", maxLength = 10)
{
  return text.length > maxLength ? (text.substring(0, maxLength - 1) + "…") : text;
}


export function isBlank(obj = {})
{
  return typeof obj === "object"
         && Object.keys(obj).every(key => obj[key] === null || obj[key] === "");
}


export function capitalize(str)
{
  return typeof str === "string" && (
    str.charAt(0).toUpperCase() + str.slice(1)
  );
}
