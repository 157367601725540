import React from "react";
import { capitalize } from "../../Helpers/Utilities";
import * as Icons from "./Icons";


const alertStyle = {
  backgroundColor: "#151515",
  color: "white",
  padding: "10px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: (
    "0 16px 24px 2px rgba(0, 0, 0, 0.14), " +
    "0 6px 30px 5px rgba(0, 0, 0, 0.12), " +
    "0 8px 10px -5px rgba(0, 0, 0, 0.3)"
  ),
  width: "300px",
  boxSizing: "border-box"
};

const buttonStyle = {
  marginLeft: "20px",
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",
  color: "#FFFFFF"
};

const colorMap = {
  info: "hsl(204, 86%, 53%)",
  success: "hsl(141, 71%, 48%)",
  error: "hsl(348, 100%, 61%)"
};


export default (props) => {
  const { message, options, style, close } = props;

  const color = colorMap[options.type],
        border = `solid 1px ${color}`;

  const IconComponent = Icons[capitalize(options.type) + "Icon"];

  return (
    <div style={{ ...alertStyle, border, ...style }}>
      <IconComponent color={color} />
      <span style={{ flex: 2 }}>
        {message}
      </span>
      <button style={buttonStyle} onClick={close}>
        <Icons.CloseIcon />
      </button>
    </div>
  );
};
